.collapsible-box {
  background: white;
  h5 {
    margin: 0;
    margin-left: 0.5rem;
    color: #242424;
  }
  &__trigger {
    display: flex;
    cursor: pointer;
    padding: 0 0 0.5rem 0;
  }
}

.card-blue {
  background: #f4f8ff;
  padding: 1rem;
  border-radius: 0.5rem;
}
