.solas-port-calls-table {
  &__added-header {
    background: #f2f8ff;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
  }
  &__title {
    font-weight: bold;
    font-size: 1.5rem;
  }
}

.add-port-call-modal {
  min-width: 50%;
  label {
    font-size: 0.8rem;
  }
  .add-port-call-modal__content {
    .add-port-call-form {
      .field_date_picker {
        margin: 0;
        flex: 1;
        display: flex;
        flex-direction: column;
        z-index: 0;
        &:has(.react-datepicker-popper) {
          z-index: 99999 !important;
        }
        &:last-child .react-datepicker__input-container {
          width: 100% !important;
        }
        .react-datepicker__input-container {
          width: 98% !important;
        }
      }
      &__row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .form-control {
          flex: 1;
          padding-right: 1rem;
          &:last-child {
            padding-right: initial;
          }
        }
      }
      #portNameDropdown .react-select__indicators,
      #portCountry .react-select__indicators {
        display: flex;
      }
    }
    .add-member__form-submission-btns {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin: 1rem 0 0 auto;
      .discard-btn {
        text-align: center;
        cursor: pointer;
      }
      .discard-btn,
      button {
        flex: 0 0 50%;
      }
    }
  }
}
