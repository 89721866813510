.alertModal{
  overflow: hidden;
  align-items: center;
  width: 430px;
  position: fixed;
  top: 80px;
  right: 3px;
  border-radius: 4px;
  padding: 10px;
  z-index: 9999999;
}

.alertModal .flex{
  display: flex;
  flex-direction: row;
}

.alertModal .button{
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  background-color: transparent;
  margin: 4px;
  padding: 5px;
}

.alertModal .button:hover{
  opacity: 0.7;
}
.alertModal .button:hover.success{
  background-color: #D1F0D2 !important;
}

.alertModal .button:hover.error{
  background-color: #fce3e3 !important;
}

.alertModal .button:hover.warning{
  background-color: #FEF3E2 !important;
}

.alertModal .button:hover.information{
  background-color: #79B1FF !important;
}

