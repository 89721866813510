.cargo-information {
  .cargo-information-form {
    margin-bottom: 24px;

    &__radio {
      margin-bottom: 24px;
    }

    &__controls {
      display: flex;
      flex-direction: column;
    }

    &__row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 24px;

      .form-control {
        flex: 1;

        &:first-child {
          margin-right: 1rem;
        }
      }
    }
  }

  .cargo-dangerous-goods {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;

    .form-control {
      flex: 0 0 50%;
      margin-right: 1rem;

      &:last-child {
        flex: 1;
        margin-right: 0;
      }
    }
  }
}
