.skip-and-next {
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .skip-btn,
  .back-btn,
  .next-btn {
    text-decoration: none;
    font-weight: bold;
  }
  .skip-btn {
    color: #bdbdbd;
  }
  .back-btn {
    display: flex;
    align-items: center;
    margin-left: 1rem;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    background-color: #E6F0FF;
    color: #006AFF;
    img {
      height: 1rem;
      margin-right: 0.75rem;
    }
    
  }
  .next-btn {
    display: flex;
    align-items: center;
    margin-left: 1rem;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    background-color: #006aff;
    color: white;
    border: 1px solid transparent;
    img {
      height: 1rem;
      margin-left: 0.75rem;
    }
    
  }
}