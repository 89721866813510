.crew-list-table {
  padding: 0;
  &__added-header {
    background: #f2f8ff;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
  }
  &__header{
    display: flex;
    flex-direction: row;
    align-items: center;
    &-title{
      flex: 1;
    }
  }
  &__header-title{
    width: 80%;
    padding: 1rem;
    align-items: center;
    & h2{
      color: var(--grey-800, #242424);
      /* L (800) */
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 800;
      line-height: 24px; /* 120% */
    }
    & p{
      color: var(--grey-800, #242424);
      /* M (500) */
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
    }
  }
  &__button{
    display: flex;
    align-items: center;
    margin: 2px;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    background-color: #006aff;
    color: white;
    border: 1px solid transparent;
    height: 90%;
    margin-bottom: 10px;
    img {
      height: 1rem;
      margin-left: 0.75rem;
    }
  }
  &__control-btns {
    display: flex;
    button {
      margin-left: 1rem;
    }
  }
  &__scrollable-area {
    overflow: scroll;
  }
}
.notification {
  background-color: #F2F8FF;
  border: 1px solid #006AFF;
  border-radius: 0.1rem;
  padding: .5rem;
  display: flex;  
  p {
    padding: 0;
    margin: 0;
  }
  &__img{
    padding: 8px 9px 8px 9px;
    border-radius: 8px 0px 0px 0px;
    background: #F2F8FF;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__title {
    color: #0055CC;
    font-weight: 800;
  }
  &__imp {
    color: #0055CC;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
  }
  ol {
    margin-block-start: .5em;
    margin-block-end: 0em;
    li {
      color: #0055CC;
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      text-align: left;
    }
  }
}
.add-crew-member-modal {
  min-width: 40%;
  label {
    font-size: 0.8rem;
  }
  .add-crew-member-modal__content {


    .add-crew-member-form {
      &__row {
        .field_date_picker{
          margin: 0;
          flex:1;
          display: flex;
          flex-direction: column;
          z-index: 0;
          &:has(.react-datepicker-popper){
            z-index: 99999 !important;
          }
          .react-datepicker__input-container{
            width: 98% !important;
          }
        }
        display: flex;
        justify-content: space-between;
        align-items: center;
        .form-control {
          flex: 1;
          padding-right: 1rem;
          &:last-child {
            padding-right: initial;
          }
        }
      }
    }
  }
  .add-member__form-submission-btns {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 1rem 0 0 auto;
    .discard-btn {
      text-align: center;
      cursor: pointer;
    }
    .discard-btn,
    button {
      flex: 0 0 50%;
    }
  }
}

.flex {
  display: flex;
  &-h{
    flex-direction: row;
  }
  &-dy{
    flex-direction: column;
  }
}
