.crew-master-data {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      padding: 0;
      margin: 0;
      font-weight: 500;
      color: #666666;
    }
    .save-and-discard-btns {
      display: flex;
      button {
        margin-left: 1rem;
      }
    }
  }
  .crew-master-data-form {
    &__row {
      display: flex;
      align-items: center;
      &:nth-child(1) .form-control {
        flex: 0 1 33%;
        &:nth-child(1) {
          flex: 0 0 33%;
        }
        &:not(:first-child) {
          padding-left: 1rem;
        }
      }
      &:nth-child(2) .form-control {
        flex: 0 1 33%;
      }
      .multi-select {
        .react-select__control {
          border: 1px solid #d7d7d7;
          font-family: Arial;
          font-size: 0.9rem;
        }
      }
    }
  }
}
