@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap');

html,
body,
#root {
  min-height: 100vh;
  font-family: 'Inter', sans-serif;
}

.layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  main {
    background: white;
    flex: 1;
    display: flex;
  }
}
.global-error-modal {
  width: 50%;
  pre {
    white-space: pre-line;
    border-radius: 0.25rem;
    padding: 1rem;
    background: rgb(243, 215, 215);
  }
}
