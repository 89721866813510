.header {
  z-index: 8000;
  padding: 1rem 1rem;
  background-color: white;
  min-height: 5vh;
  display: flex;
  align-items: center;
  color: black;
  box-shadow: 0 -1rem 2rem black;
  .ncap-logo {
    width: 3rem;
    height: 3rem;
    &:hover {
      transform: scale(1.05);
    }
  }
  &__buttons {
    margin-left: auto;
    display: flex;
    .generate-form-btn {
      margin-right: 1rem;
      &:hover {
        background: darken($color: #006aff, $amount: 3);
      }
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      color: #396899;
      li {
        margin-left: 0.25rem;
        display: flex;
        align-items: center;
        cursor: pointer;
        border-radius: 0.5rem;
        padding: 0.25rem;
        transition: 200ms;
        svg {
          display: flex;
          transition: 200ms;
          width: 1.5rem;
          height: 1.5rem;
        }
        &:hover {
          transform: scale(1.1);
          background: #e2f0fd;
        }
        &:nth-child(3) {
          &:hover {
            svg {
              transform: rotate(360deg);
              transform-origin: center center;
            }
          }
        }
        &:hover {
          svg {
            display: flex;
            path {
              fill: #0064f0;
            }
          }
        }
      }
    }
  }
}
