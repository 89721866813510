.date-picker {
  margin-bottom: 0.5rem;

  &__inner {
    display: flex;
  }

  &__label {
    font-weight: bold;
    display: flex;
    margin-bottom: 0.5rem;
    color: #000a19;
  }

  input {
    width: 100%;
    padding: 0.5rem;
    border-radius: 0.25rem;
    border: 1px solid #d7d7d7;
    box-sizing: border-box;
    height: 2.5rem;

    &.error {
      border: 2px solid #b4220e;
    }

    &:focus {
      border: 1px solid #005a77;
      outline: none;
    }

    background-image: url("./images/datepickerIcon.svg");

    &:disabled {
      background-image: url("./images/datepickerIconDisabled.svg");
    }

    background-repeat: no-repeat;
    background-position: right;
    background-size: 0.9rem;
    background-origin: content-box;
  }

  .react-datepicker-popper {
    padding-top: 0.25rem;

    .react-datepicker {
      box-shadow: 1px 0px 5px grey;
      border: none;
    }

    .react-datepicker__navigation {
      .react-datepicker__navigation-icon {
        &::before {
          border-color: black;
        }
      }
    }

    .react-datepicker__month-container {
      .react-datepicker__header {
        border-bottom: initial;
        background-color: white;
      }
    }

    .react-datepicker__time-container {
      border: none;
      border-left: 1px solid #aeaeae;

      .react-datepicker__header {
        background-color: #e7f0ff;
        border-bottom: none;
        display: none;

        .react-datepicker-time__header {
          color: black;
        }
      }

      .react-datepicker__time {
        .react-datepicker__time-list-item.react-datepicker__time-list-item--selected {
          background-color: #0069fd;
        }
      }
    }

    .react-datepicker__day.react-datepicker__day--selected {
      background-color: #0069fd;
    }

    .react-datepicker__month-dropdown-container,
    .react-datepicker__year-dropdown-container {
      padding: 0.5rem 0;

      .react-datepicker__month-read-view--selected-month,
      .react-datepicker__year-read-view--selected-year {
        padding: 0 0.5rem;
      }
    }

    .react-datepicker__year-dropdown,
    .react-datepicker__month-dropdown {
      background-color: white;
    }

    .react-datepicker__navigation--years-previous {
      background-image: url("../styles/images/arrow-down.svg");
      background-size: 60%;
      background-repeat: no-repeat;
      background-position: center;
    }
    .react-datepicker__navigation--years-upcoming {
      background-image: url("../styles/images/arrow-down.svg");
      background-size: 60%;
      background-repeat: no-repeat;
      background-position: center;
      transform: rotateX(180deg);
    }
  }
}