.error-normal {
  color: #006aff;
  font-size: 0.9rem;
  margin-top: 8px;
  margin-bottom: 24px;
  font-weight: normal;
}

.error-dropdown {
  color: #006aff;
  font-size: 0.9rem;
  margin-top: 8px;
  margin-bottom: 24px;
  font-weight: normal;
}

.error-table {
  color: #006aff;
  font-size: 0.9rem;
  margin-bottom: 24px;
  text-align: right;
  font-weight: normal;
}

.input-error-border {
  border: 1px solid #006aff !important;
  border-radius: 4px;
  box-shadow: none;
}
