.dangerous-goods-table {
  padding: 1rem 0;

  &__added-header {
    background: #f2f8ff;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
  }

  &__title {
    font-weight: bold;
    font-size: 1.5rem;
  }

  &__control-btns {
    display: flex;

    button {
      margin-left: 1rem;
    }
  }

  &__scrollable-area {
    overflow: scroll;
  }
}

.add-dangerous-good-modal {
  min-width: 50%;

  label {
    font-size: 0.8rem;
  }

  .add-dangerous-good-modal__content {
    hr {
      color: #e5e5e5;
      background-color: #e5e5e5;
      height: 1px;
      border: none;
    }
    .add-dangerous-good-form {
      &__row {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 14px;
      }
      &__row2 {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        gap: 14px;
        @media (max-width: 1200px) {
          grid-template-columns: repeat(3, 1fr);
        }
      }
    }
  }

  .add-dangerous-good__form-submission-btns {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 1rem 0 0 auto;
    gap: 10px;

    .discard-btn {
      background: #e0edff;
      text-align: center;
      cursor: pointer;
      color: #006aff;
    }

    .discard-btn,
    button {
      flex: 0 0 50%;
    }
  }
}

.un-container {
  position: relative;

  .list {
    position: absolute;
    left: 0;
    background: white;
    max-height: 10rem;
    width: 20rem;
    border-radius: 0.25rem;
    border: 1px solid black;
    overflow: scroll;
    font-size: 0.8rem;
    &__item {
      cursor: pointer;
      // background: #a6c3ff5e;
      margin: 0.25rem 0;
      padding: 0.5rem 0.5rem;

      &:hover {
        background: #a6c3ff5e;
      }
    }
  }

  .un__empty-case {
    display: block;
    padding: 0.5rem 1rem;
  }
}

.dangerous-goods-checkbox-group {
  margin-bottom: 1rem;

  .checkbox-wrapper {
    display: flex;
    align-items: center;
    gap: 0.4rem;
  }

  input[type='checkbox'] {
    cursor: pointer;
    appearance: none;
    background-color: #fff;
    margin: 0;
    width: 14px;
    height: 14px;
    border: 0.1rem solid #081022;
    border-radius: 0.2em;
    display: grid;
    place-content: center;
    transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, background-image 0.2s ease-in-out;

    &:checked {
      background-color: #000;
      border-color: #000;
      background-image: url('../../../../forms/styles/images/tick.svg');
      background-size: 8px 8px;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  label {
    font-size: 13px;
    font-weight: 500;
    color: #000a19;
    cursor: pointer;
  }
}
