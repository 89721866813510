.multi-select {
  margin-bottom: 0.5rem;
  .sublabel {
    margin-bottom: 1rem;
    display: block;
    color: #424957;
  }
  .react-select__value-container {
    font-size: 0.8rem;
    font-family: Arial, Helvetica, sans-serif;
  }
  .react-select-container.error {
    &.error {
      .react-select__control {
        border: 2px solid #b4220e;
      }
    }
    .react-select__control--is-disabled {
      border: 1px solid #d7d7d7;
      border-radius: 0.2rem;
    }
  }
  .react-select__single-value.react-select__single-value--is-disabled {
    color: #999999;
  }
}
