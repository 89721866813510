.button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #006aff;
  border: none;
  color: white;
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  font-weight: 600;
  cursor: pointer;
  &__icon-left {
    padding-right: 0.5rem;
  }
  &__icon-right {
    padding-left: 0.5rem;
  }
  &.light {
    background-color: #e0edff;
    color: #006aff;
    font-weight: 600;
  }
  &.transparent{
    background-color: transparent;
    color: #006aff;
  }
  &.danger {
    background-color: #fce3e3;
    color: #eb0000;
    font-weight: 600;
  }
  &.light-table,
  &.light-table-no-border {
    background-color: transparent;
    color: #006aff;
    font-weight: 600;
    border: 1px solid #006aff;
  }
  &.light-table-no-border {
    border-color: transparent;
  }
  &.danger-table {
    background-color: transparent;
    color: #eb0000;
    font-weight: 600;
    border: 1px solid #eb0000;
  }
  &:disabled {
    // background-color: grey;
    opacity: 0.5;
    cursor: not-allowed !important;
  }
  &.hidden {
    display: none;
  }
}
