.confirm-delete-modal {
  &__title {
    display: flex;
    img {
      width: 3rem;
      margin-right: 1rem;
    }
    h3 {
      span {
        color: #eb0000;
      }
    }
  }
  &__btns {
    display: flex;
    align-items: center;
    .cancel-btn {
      text-align: center;
      cursor: pointer;
    }
    .cancel-btn,
    button {
      flex: 0 0 50%;
    }
    button {
      background: #eb0000;
    }
  }
}
