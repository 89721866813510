.dangerous-goods {
  h2 {
  }
  h3 {
    font-size: 1rem;
  }
  &__type {
    margin-top: 1rem;
    .radio-buttons-custom__container {
      display: flex;
      flex-direction: column;
    }
  }
  &__questions {
    margin-top: 1rem;
    .radio-buttons-custom {
      &:nth-child(2) {
        margin-top: 1rem;
      }
    }
    .input {
      width: 50%;
      // margin-right: 1rem;
    }
  }
  &-form__contact-information {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    .form-control {
      &:nth-child(1) {
        flex: 0 0 50%;
        margin-right: 1rem;
      }
      &:nth-child(2) {
        flex: 1;
      }
    }
    div {
      display: flex;
      justify-content: space-between;
    }
  }
  .ism-extra-fields-section {
    margin-top: 1rem;
  }
  .dangerous-goods-form__certificate-number {
    display: flex;
    justify-content: flex-start;
  }
  .dangerous-goods-form__row {
    display: flex;
    justify-content: space-between;
    .field_date_picker {
      font-size: 16px;
      margin: 0;
      flex: 1;
      display: flex;
      flex-direction: column;
      z-index: 0;
      &:has(.react-datepicker-popper) {
        z-index: 99999 !important;
      }
      .react-datepicker__input-container {
        width: 100% !important;
      }
    }
    .form-control {
      &:nth-child(1) {
        flex: 0 0 50%;
        margin-right: 1rem;
      }
      &:nth-child(2) {
        flex: 1;
      }
    }
  }
}
