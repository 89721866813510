.modal {
  $component: &;
  &__children {
    padding: 1em 1.5em;
  }
  &__container-center{
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
    gap: 8px;
  }
  &__header-container {
    display: flex;
    &__icon{
      margin-right: 25px;
    }
    &__column {
      display: flex;
      flex-direction: column;
    }
    &__buttons{
      display: flex;
      justify-content: space-evenly;
      > * {
        padding: 8px 32px;
        min-width: 45%;
      }
    }
    &__text{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 4px;
      flex: 1 0 0;
      align-self: stretch;
      &__title{
        color: var(--grey-800, #242424);
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 800;
        line-height: 24px;
      }
      &__subtitle{
        color: var(--grey-800, #242424);
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 150% */
      }
    }
    #{$component}__title {
      color: #242424;
      font-size: 1.25rem;
      font-weight: 700;
      margin: 0 0 1rem 0;
    }
  }
  &__close-button {
    cursor: pointer;
    position: absolute;
    right: 0.5em;
    top: 0.5em;
    z-index: 9999; // when content inside is absolutely positioned, this icon needs z-index for onClick to function. E.g. intro video in talent profile
  }
}

.ReactModal__Content {
  font-family: 'Inter', sans-serif;
  min-width: 30%;
}

.ReactModal__Overlay {
  z-index: 9999;
}

// Transition animation
.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-wrapper {
  max-height: 90vh;
  padding: 0;
  border: none;
  top: initial;
  left: initial;
  right: initial;
  bottom: initial;
  position: relative;
  outline: none;
  background-color: white;
  border-radius: 0.3rem;
}

.modal-scroll {
  overflow-y: auto;
}