.eta-and-etd {
  .eta-etd-form {
    &__controls {
      width: 40%;
    }

    .field_date_picker {
      margin: 0;
      flex: 1;
      display: flex;
      flex-direction: column;
      z-index: 0;
      &:has(.react-datepicker-popper) {
        z-index: 99999 !important;
      }
      .react-datepicker__input-container {
        width: 100% !important;
      }
    }
  }
}
