.bunkering-detail {
  .bunkering-detail-form {
    &__controls {
      width: 95%;
    }
    h2 {
      margin: 0;
    }
    p {
      margin: 0;
      color: #666666;
      margin-top: 0.5rem;
    }
    .radio-buttons-custom {
      margin-bottom: 1rem;
      label {
        margin-bottom: 0;
      }
      .radio-button-custom {
        padding: 0.5rem 1.5rem;
      }
    }
    &__first-row {
      display: flex;
    }
    &__first-column {
      flex: 1;
      padding-right: 1rem;
      box-sizing: border-box;
    }
    &__second-column {
      flex: 1;
      padding-right: 1rem;
    }
    &__second-row {
      .radio-buttons-custom {
        margin-top: 1rem;
      }
    }
  }
}
