.sidebar {
  background: white;
  z-index: 9000;
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 2px solid #e0e0e0;

  h4 {
    width: 100%;
    font-size: 20px;
    padding: 0 1rem;
    box-sizing: border-box;
    font-weight: bold;
    color: #424957;
  }

  ul {
    font-weight: 500;
    width: 100%;
    margin: 0;
    padding: 0;
    cursor: pointer;
    list-style: none;

    li {
      margin: 0;
      display: flex;
      flex-direction: column; /* Stack items vertically */
    }
  }

  &__single-item,
  &__multiple-items {
    a {
      text-decoration: none;
      color: #000a19;
      padding: 1rem;
      flex: 1;
      display: flex;
      align-items: center;
      &:hover {
        background-color: #f4f8ff;
      }
      &.active {
        background-color: #e6f0ff;
        color: #006aff;
        font-weight: bold;
      }
    }
  }

  &__multiple-items {
    > div {
      flex: 1 1;
    }

    &-parent {
      padding: 1rem;
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    .sidebar__icon {
      margin-left: auto;
      display: flex;
      align-items: center;

      svg {
        width: 25px;
        height: 25px;
      }
    }

    &-children {
      margin-left: 1rem;
      padding-left: 1rem;
      border-left: 2px solid #e0e0e0;

      li {
        a {
          padding-left: 2.5rem;
          font-size: 0.95rem;
        }
      }
    }
  }
}
