.welcome {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-evenly;
  // text-align: left;
  padding: 0 10%;
  font-size: 2.5vh;
  &__badge {
    width: inherit;
    align-self: flex-end;
    justify-self: flex-end;
    margin-top: 0;
  }
  h1 {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  &__message {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #006aff;
    border: none;
    color: white;
    border-radius: 0.25rem;
    padding: 0.5rem 1.5rem;
    font-size: 2vh;
    cursor: pointer;
    img {
      height: 0.75rem;
    }
  }
  
}