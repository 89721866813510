.passenger-list-table {
  padding: 2rem 0;
  &__added-header {
    background: #f2f8ff;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
  }
  &__title {
    font-weight: bold;
    font-size: 1.5rem;
  }
  &__scrollable-area {
    overflow: scroll;
  }
}

.passenger-list-title {
  margin-bottom: 24px;
}

.add-passenger-member-modal {
  min-width: 40%;
  label {
    font-size: 0.8rem;
  }
  .add-passenger-member-modal__content {
    .add-passenger-member-form {
      &__row {
        .field_date_picker{
          margin: 0 16px 0 0;
          flex:1;
          display: flex;
          flex-direction: column;
          z-index: 0;
          &:has(.react-datepicker-popper){
            z-index: 99999 !important;
          }
          .react-datepicker__input-container{
            width: 100% !important;
          }
        }
        display: flex;
        justify-content: space-between;
        align-items: center;
        .form-control {
          flex: 1;
          padding-right: 1rem;
          &:last-child {
            padding-right: initial;
          }
        }
      }
    }
    .button {
      margin: 1rem 0 0 auto;
    }
  }
  .add-member__form-submission-btns {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 1rem 0 0 auto;
    .discard-btn {
      text-align: center;
      cursor: pointer;
    }
    .discard-btn,
    button {
      flex: 0 0 50%;
    }
  }
}
