.upload-download-box {
  border: 1px solid #d5d5d5;
  border-radius: 0.3rem;
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0;
  position: relative;

  .required-document-label {
    color: #006aff;
    font-weight: normal;
    font-size: 0.9rem;
    margin-left: 2px;
    position: absolute;
    bottom: -20px;
    left: 0;
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__bottom {
    padding: 1rem 1rem 0 3rem;
    display: flex;
  }
  &__left {
    display: flex;
    align-items: center;
    flex: 0 0 50%;

    img {
      padding: 0 1rem;
    }
  }

  &__right {
    flex: 0 0 48%;
    margin-left: 2%;
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;

    button {
      border: none;
      padding: 0.5rem 1rem;
      font-weight: bold;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-right: 0.5rem;
      border-radius: 0.5rem;

      img {
        padding-right: 0.5rem;
      }

      &.download-btn {
        background-color: #f3f3f3;
        cursor: pointer;
      }

      &.upload-btn {
        background-color: #e0edff;
        color: #006aff;
        cursor: pointer;
      }
    }

    .file-name {
      color: #006aff;
      padding: 0 1rem;
      display: flex;
      align-items: center;
      font-size: 0.8rem;

      &__name {
        font-weight: bold;
        background-color: #e0ecff;
        color: #0141cc;
        padding: 0.5rem 1rem;
        border-radius: 0.5rem;
      }

      &__x-mark {
        margin-left: 0.5rem;
        cursor: pointer;
        background: #f3f3f3;
        padding: 0.5rem 0.75rem;
        border-radius: 0.5rem;
        color: black;

        &:hover {
          background: darken($color: #f3f3f3, $amount: 2);
        }
      }
    }

    .loader {
      font-size: 1rem;
      margin-right: 1rem;
    }
  }

  h5 {
    margin: 0;
  }

  &__certificate-date {
    background: #f2f8ff;
    margin-right: 1rem;
    padding: 0.25rem 1rem 0.25rem 1rem;
    border-radius: 1rem;
    font-weight: bold;
    font-size: 0.8rem;
  }

  &__last-updated {
    font-size: 0.8rem;
    color: #006aff;
  }
}

.add-certificate-modal {
  &__title {
    display: flex;
    align-items: center;

    img {
      padding-right: 1rem;
      width: 3rem;
    }

    h3,
    p {
      margin: 0;
    }
  }

  &__upload-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 0;
    border-radius: 0.5rem;
    border: 1px dashed #b3b3b3;
    margin: 1rem 0;

    p {
      margin: 0;
    }

    p,
    button {
      margin: 0.5rem 0;
    }

    button {
      background: #e0edff;
      border: none;
      padding: 0.5rem;
      border-radius: 0.25rem;
      color: #006aff;
      font-weight: bold;
      cursor: pointer;
    }
  }

  &__back-btn {
    color: #006aff;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
  }
}

.custom-file-upload {
  display: inline-block;
  padding: 0.75rem 1.25rem;
  margin: 0.5rem 0;
  cursor: pointer;
  background-color: #e0edff;
  border: none;
  border-radius: 0.25rem;
  color: #006aff;
  font-weight: bold;
  cursor: pointer;
}

.custom-file-upload input[type='file'] {
  display: none;
}

.remove-file-confirmation-modal {
  &__title {
    font-size: 1.25rem;
    font-weight: bold;
  }

  &__subtitle {
  }

  &__control-btns {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;

    button {
      flex: 1;
    }
  }

  &__cancel-btn {
    background-color: white;
    border: none;
    cursor: pointer;
    font-weight: bold;
  }

  &__remove-btn {
    background-color: #006aff;
    color: white;
    border: none;
    cursor: pointer;
    padding: 0.5rem 0;
    border-radius: 0.3rem;
  }
}

.certificate-dates-form {
  margin-top: 1rem;
  .field_date_picker {
    margin: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    z-index: 0;
    &:has(.react-datepicker-popper) {
      z-index: 99999 !important;
    }
    .react-datepicker__input-container {
      width: 100% !important;
    }
  }
  .file-name {
    font-weight: bold;
    padding: 0.5rem 0;
  }
  &__control-btns {
    display: flex;
    justify-content: space-around;
    margin-top: 1rem;

    button {
      flex: 1;
      border: none;
      padding: 0.5rem 0;
      border-radius: 0.3rem;
      cursor: pointer;
    }
  }

  &__back-btn {
    background-color: white;
  }

  &__save-btn {
    background-color: #006aff;
    color: white;
  }
}
