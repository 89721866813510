.progress-bar {
  margin: 1rem 0 1rem 0;
  display: flex;
  align-items: center;
  &__progress-indicator {
    flex: 1;
    background: #8abaff;
  }
  &__inner {
    background: #006AFF;
    height: 0.25rem;
    transition: 200ms;
  }
  &__pages {
    margin: 0 0 0 2rem;
    color: #424957;
    font-weight: 500;
  }
}
