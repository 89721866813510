.health-wrapper {

  .health-declaration-form {
    &__row {
      display: grid;
      margin-bottom: 16px;
      grid-column-gap: 16px;
      .field_date_picker{
        font-size: 16px;
        margin: 0;
        flex:1;
        display: flex;
        flex-direction: column;
        z-index: 0;
        &:has(.react-datepicker-popper){
          z-index: 99999 !important;
        }
        .react-datepicker__input-container{
          width: 100% !important;
        }
      }
      &.two {
        grid-template-columns: 1fr 1fr;
      }
      &.three {
        grid-template-columns: 1fr 1fr 1fr;
      }
      .form-control.input {
        max-width: 400px;
        label {
          font-size: 14px;
        }
      }
    }
  }

}

.health-modal {
  min-width: 40%;
  label {
    font-size: 0.8rem;
  }
  .health-modal__content {
    .state-particular{
      .field_date_picker{
        padding-left: 0 !important;
      }
    }
    .health-form {
      .field_date_picker{
        margin: 0;
        flex:1;
        display: flex;
        flex-direction: column;
        z-index: 0;
        &:has(.react-datepicker-popper){
          z-index: 99999 !important;
        }
        &:last-child{
          padding-left: 0.8rem;
        }
        &:last-child .react-datepicker__input-container{
          width: 100% !important;
        }
        .react-datepicker__input-container{
          width: 98% !important;
        }
      }
      &__row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .form-control {
          flex: 1;
          padding-right: 1rem;
          &:last-child {
            padding-right: initial;
          }
        }
      }
      #portNameDropdown > .react-select__control > .react-select__indicators {
        display: none;
      }
    }
  }
}
