.form-elements {
  display: flex;
  flex-direction: column;
  width: 40%;
  .field_date_picker{
    margin: 0;
    flex:1;
    display: flex;
    flex-direction: column;
    z-index: 0;
    &:has(.react-datepicker-popper){
      z-index: 99999 !important;
    }
  }
}

textarea,
select,
.radio-button,
.checkbox {
  font-family: Arial, Helvetica, sans-serif; // TODO remove this and add font from design
}
