$form-modal-width: 357px;
$instructions-modal-width: 600px;
$instructions-modal-height: 200px;
$spacing-unit: 1rem;
$primary-color: #000a19;
$border-color: #e5e5e5;

.form-sent-confirmation-modal {
  width: $form-modal-width;
  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    & > p {
      font-weight: 800;
      font-size: 1.25rem;
      line-height: 1.5rem;
      text-align: center;
    }
  }
}

.port-call-instructions-modal {
  width: $instructions-modal-width;
  height: $instructions-modal-height;
  & > div {
    padding: 1.5rem;
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;

    & > .page-header {
      text-align: center;
      & > .page-title {
        font-size: 18px;
        margin-bottom: 16px;
      }
      & > .page-subtitle {
        font-size: 18px;
      }
    }
  }

  &__body {
    padding: 1.5rem 0;
    & > .instructions {
      height: 8rem;
      border-radius: 5px;
      border: 1px solid $border-color;
      margin-bottom: 0.5rem;
      padding: 1rem;
      color: $primary-color;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #ccc;
        border-radius: 3px;
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    & > button {
      flex-grow: 0;
      width: 48%;
      padding: 0.5rem;
      border-radius: 10px;
      &:focus {
        outline: 2px solid #0044cc;
      }
    }
  }
}

.light-button {
  background-color: #e0edff;
  color: #006aff;
  font-weight: 600;
  border: 1px solid #006aff;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .form-sent-confirmation-modal,
  .port-call-instructions-modal {
    width: 90%;
  }
}
