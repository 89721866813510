.specify-crew-number {
  h3 {
    margin: 0;
    font-size: 1rem;
    padding-bottom: 1rem;
  }
  .specify-crew-number-form {
    &__row {
      display: flex;
      .form-control {
        padding-right: 1rem;
        input {
          width: 100%;
        }
      }
    }
  }
}
.specify-stowaways-form {
  padding: 24px 0px;
}
.specify-stowaways-form__row {
  display: flex;
  .form-control {
    padding-top: 24px;
    padding-right: 1rem;
    input {
      width: 100%;
    }
  }
}
