.port-clearance-form-request {
  .search-vessel-form {
    &__form-elements {
      display: flex;
      justify-content: space-between;
      .input {
        flex: 1;
        &:nth-child(1) {
          padding-right: 2rem;
        }
      }
    }
    .form-error {
      color: #b4220e;
      font-size: 0.8rem;
      margin-top: 0.25rem;
      font-weight: 500;
    }
    button {
      display: flex;
      align-items: center;
      background: #006aff;
      border: none;
      color: white;
      border-radius: 0.25rem;
      padding: 0.5rem 1rem;
      cursor: pointer;
      img {
        padding-right: 0.5rem;
      }
    }
    &__submit-btn {
      display: flex;
      justify-content: flex-end;
      padding: 1rem 0;
    }
  }
  .search-results__loading {
    display: flex;
    padding: 2rem;
    justify-content: center;
  }
  .search-results__no-results {
    padding: 1rem;
    text-align: center;
  }
  .search-result-list-item {
    margin-bottom: 1rem;
    a {
      text-decoration: none;
      color: #006aff;
    }
    .card {
      display: flex;
      justify-content: space-between;
      padding: 1rem 1.5rem;
      .search-result-list-item__vessel-name {
        transition: 500ms;
      }
      img {
        height: 1rem;
        transition: 500ms;
      }
      &:hover {
        .search-result-list-item__vessel-name {
          transform: translate(0.25rem, 0);
        }
        img {
          transform: translate(0.25rem, 0);
        }
      }
    }
  }
}
