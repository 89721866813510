.blocked-page-layout {
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  main {
    background: #fbfdfe;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: url("./images/blocked-page-background.jpeg") center center no-repeat, white;
    background-size: contain;

    header {
      display: flex;
      flex-flow: column;
      align-items: center;
      padding-top: 60px;

      h3 {
        font-size: 72px;
        margin: 0 0 24px 0;
      }
  
      p {
        font-size: 36px;
        margin: 0;
      }

    }

    footer {
      margin-top: auto;
      padding-bottom: 100px;

      button {
        width: 410px;
        height: 50px;
        font-size: 16px;
      }
    }

  }

}