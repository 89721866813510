.table-preheader {
  background: #f2f8ff;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  &__title {
    font-weight: bold;
    font-size: 1.5rem;
  }
  &__control-btns  {
    display: flex;
    & > button {
      margin-left: 1rem;
    }
  }
}
.flex {
  display: flex;
  &-h{
    flex-direction: row;
  }
  &-dy{
    flex-direction: column;
  }
}
.table-body {
  overflow: scroll;
}

table.table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  overflow: scroll;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  .edit-btn,
  .delete-btn {
    width: 5px;
    cursor: pointer;
    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }
  .editing-placeholder {
    width: 5px;
  }

  td,
  th {
    padding: 1rem;
    display: flex;
    align-items: center;
  }

  td {
    white-space: normal;
    word-break: break-word;
    border: 1px solid #ddd;
    border-left: none;
    border-right: none;
    border-top: none;
  }

  tr:hover {
    background-color: #f5f7f8;
  }

  th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #f2f8ff;
    color: #043147;
  }

  thead {
    tr {
      box-shadow: 1px 1px 5px rgb(217, 217, 217);
    }
    position: relative;
  }
  tbody {
    z-index: 1000;
  }
}

.page {
  overflow: scroll; // added this for table scrolling
}

.body-cell {
  border: 1px solid black;
}

.body-number-cell {
  padding: 1rem;
  display: flex;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 50px;
  // flex: 50 0 auto;
  box-sizing: border-box;
}

.head-number-cell {
  box-sizing: border-box;
  width: 50px;
}
