.auth-page {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .auth-box {
    background: white;
    border-radius: 0.5rem;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h3 {
      color: #0041cb;
    }
    button {
      background: #0055cc;
      border: none;
      color: white;
      border-radius: 0.25rem;
      padding: 0.5rem 1rem;
      cursor: pointer;
      width: 100%;
    }
    p {
      text-align: center;
      a {
        text-decoration: none;
        color: #0041cb;
      }
    }
  }
}