.specify-passengers-number {
  h3 {
    margin: 0;
    font-size: 1rem;
    padding-bottom: 1rem;
  }
  .specify-passengers-number-form {
    &__row {
      display: flex;
      .form-control {
        padding-right: 1rem;
        input {
          width: 100%;
        }
      }
    }
  }
}