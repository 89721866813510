.auth-layout {
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  main {
    background: #fbfdfe;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: url("./images/port-background.jpeg") center center no-repeat, #4278d2;
    background-size: cover;
  }
  
}
