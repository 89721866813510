.select {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
  &__label {
    font-weight: bold;
    display: flex;
    margin-bottom: 0.5rem;
    color: #000A19;
  }
  select {
    padding: 0.5rem;
    border-radius: 0.2rem;
    border: 1px solid #d7d7d7;
    &:focus {
      border: 1px solid #005a77;
      outline: none;
    }
  }
}
