.vessel-data {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .save-and-discard-btns {
      display: flex;
      button {
        margin-left: 1rem;
      }
    }
  }
  .vessel-data-form {
    .field_date_picker{
      margin: 0;
      flex:1;
      display: flex;
      flex-direction: column;
      z-index: -1 !important;
      font-size: 16px !important;
      &:has(.react-datepicker-popper){
        z-index: 99999 !important;
      }
      .react-datepicker__input-container{
        width: 100% !important;
      }
    }

    .react-select__menu-portal{
      z-index: 10;
    }
    &__controls {

      width: 90%;
    }
    &__first-row {
      display: flex;
    }
    &__first-column {
      flex: 1;
      padding-right: 1rem;
      box-sizing: border-box;
    }
    &__second-column {
      flex: 1;
      padding-right: 1rem;
    }
    &__second-row {
      .radio-buttons-custom {
        margin-top: 1rem;
      }
    }
    .react-select__single-value--is-disabled  {
      color: #545454;
    }
  }
}
