.general-information {
  &__section {
    margin-bottom: 1rem;
  }
  .general-information-form {
    &__form-elements {
      display: flex;
      justify-content: space-between;
      .multi-select {
        &:nth-child(1) {
          padding-right: 2rem;
        }
      }
      .input {
        &:nth-child(2) {
          padding-right: 2rem;
        }
      }
      .input,
      .multi-select {
        flex: 1;
        .react-select-container {
          cursor: text;

          .react-select__control.react-select__control--is-focused {
            border: 1px solid;
            border-color: #005a77;
            box-shadow: none;
          }
        }
      }
    }
  }

  &__info-row {
    display: flex;
  }
  &__info-column {
    display: flex;
    flex: 0 0 20%;
    flex-direction: column;
  }
  &__info-heading {
    font-weight: 600;
    margin-bottom: 0.25rem;
  }
}

.mandatory-documents {
  width: 50%;
}

.port-call-modal {
  width: 25rem;
  min-width: initial;
}

.generate-form-modal {
  .submit-button {
    width: 100%;
    margin-top: 1rem;
  }
  &__in-progress {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
  }
}
.form-generated-success {
  &__label {
    font-weight: bold;
    display: flex;
    margin-bottom: 0.5rem;
    color: #000a19;
    display: flex;
    align-items: center;
    img {
      padding-right: 1rem;
    }
  }
  .link-to-copy {
    padding: 0.5rem;
    border: 1px solid #cccccc;
    border-radius: 0.2rem;
    cursor: pointer;
    font-size: 0.8rem;
    text-decoration: underline;
  }
  &__buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    .copy-link-btn {
      button {
        width: 100%;
        img {
          padding-right: 0.5rem;
        }
      }
    }
  }
  &__close {
    flex: 1;
    text-align: center;
    color: #006AFF;
    font-weight: bold;
    cursor: pointer;
  }
}
