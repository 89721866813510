.inspections {
  .warning {
    background-color: #fef3e2;
    border: 1px solid #f59e0b;
    border-radius: 0.5rem;
    padding: 1rem;
    p {
      padding: 0;
      margin: 0;
    }
    &__title {
      color: #b45309;
      font-weight: 800;
    }
    ul {
      list-style: none; /* Remove default bullets */
      padding: 0 2rem;
      li {
        color: #b45309;
        &:before {
          content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
          color: #b45309; /* Change the color */
          font-weight: bold; /* If you want it to be bold */
          display: inline-block; /* Needed to add space between the bullet and the text */
          width: 1em; /* Also needed for space (tweak if needed) */
          margin-left: -1em; /* Also needed for space (tweak if needed) */
        }
      }
    }
  }

  .inspactions-form {
    .field_date_picker{
      margin: 0;
      flex:1;
      display: flex;
      flex-direction: column;
      z-index: 0;
      &:has(.react-datepicker-popper){
        z-index: 99999 !important;
      }
      .react-datepicker__input-container{
        width: 95% !important;
        margin-right: 5%;
      }
    }
    &__first-row,
    &__second-row,
    &__third-row {
      padding-top: 1rem;
    }
    &__third-row {
      .form-control {
        padding-bottom: 1rem;
      }
    }
    &__second-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .form-control {
        flex: 1;
        &:nth-child(1) {
          padding-right: 1rem;
        }

      }
    }
    &__third-row {
      width: 90%;
    }

  }
}
