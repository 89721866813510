.general-declaration {
  &-form {
    h3 {
      margin-bottom: 0;
    }
    &__first-row {
      margin-bottom: 1rem;
      .checkbox {
        margin-top: 0;
      }
    }
  }
}

.submitSignatureCanvas {
  max-width: 500px;
  height: 200px;
}

.masterSignatureTitle {
  margin-top: 1.5rem;
  font-weight: bold;
  display: flex;
  color: #000a19;
}

.canvasDiv {
  width: 540px;
  border: 1px solid black;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  button {
    margin-top: 5px;
    border: none;
    background-color: transparent;
    color: grey;
    width: auto;
    height: 10px;
    text-decoration: underline grey;
    &:hover {
      cursor: pointer;
    }
  }
}

.validationCheck {
  color: #006aff;
  font-size: 0.9rem;
  margin-top: 8px;
  font-weight: normal;
}
