.local-formalities {
  margin-top: 20px;

  .section {
    margin-bottom: 20px;

    .section-heading {
      font-size: 20px;
      font-weight: 800;
      line-height: 24px;
    }

    .section-subheading {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      padding: 5px 0;
    }
  }

  .doc-list-container {
    display: flex;
    flex-wrap: wrap;
  }

  .documents-list {
    .upload-download-box {
      flex: 0 0 48%;
      min-height: 75px;
      width: 100%;
      padding-right: 10px;
      margin: 10px;
      flex-direction: column;
      &:nth-child(1),
      &:nth-child(3) {
        margin-right: 2%;
      }
    }
  }
}
