.purpose {
  .purpose-form {
    &__controls {
      width: 70%;
    }
    h2 {
      margin: 0;
    }
    p {
      margin: 0;
      color: #666666;
      margin-top: 0.5rem;
    }
    .radio-buttons-custom {
      margin-bottom: 1rem;
      label { 
        margin-bottom: 0;
      }
      .radio-button-custom {
        padding: 0.5rem 2.5rem;
      }
    }
    .supplies {
      &__title {
        margin: 2rem 0;
      }
      &__list { 
        .form-control {
          display: flex;
          align-items: center;
          justify-content: space-between;
          label {

          }
        }
      }
    }
  }
}