.input {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
  &__label {
    font-weight: bold;
    display: flex;
    margin-bottom: 0.5rem;
    color: #000a19;
  }
  input {
    padding: 0.5rem;
    border-radius: 0.2rem;
    border: 1px solid #d7d7d7;
    height: 2.5rem;
    box-sizing: border-box;
    font-size: 0.8rem;
    &:focus {
      border: 1px solid #005a77;
      outline: none;
    }
    &:disabled {
      color: #999999;
    }
  }
}
