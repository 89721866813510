.solas-questions-form {
  h2 {
    font-size: 1.5rem;
  }
  &__row {
    display: flex;
    margin-top: 1rem;
    .form-control {
      flex: 1 0 33%;
      box-sizing: border-box;
      &:nth-child(1),
      &:nth-child(2) {
        padding-right: 1rem;
      }
    }
    .field_date_picker {
      font-size: 16px;
      margin: 0;
      flex: 1 0 33%;
      display: flex;
      flex-direction: column;
      z-index: 0;
      &:has(.react-datepicker-popper) {
        z-index: 99999 !important;
      }
      .react-datepicker__input-container {
        width: 100% !important;
      }
    }

    & > label {
      margin: 0 !important;
    }

    .dependant-input {
      display: flex;
      flex-direction: column;
      flex: 0 0 50%;
    }
  }
}
