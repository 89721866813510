.summary {
  .documents-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .upload-download-box {
      flex: 0 0 48%;
      margin-bottom: 2rem;
    }
  }

  .summary-documents-block {
    margin: 0 0 36px 0;

    div:has(:disabled) {
      background-color: rgb(247, 247, 247);
      margin: 0px;
    }
  }

  .documents-block {
    margin-bottom: 16px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 24px;
    grid-row-gap: 16px;
  }

  .document-type-title {
    font-size: 18px;
    height: 32px;
    margin: 0 0 16px 0;
    display: flex;
    align-items: center;

    &_with-title-button {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }

  .document-box {
    border: 1px solid #d5d5d5;
    border-radius: 5px;

    &__fade-in {
      animation: fade-in 0.5s ease-in-out;
    }

    .document-box-content {
      width: 100%;
      padding: 8px 12px;
      display: flex;
      align-items: center;

      .document-icon {
        padding: 0 10px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .document-main-info {
        display: flex;
        flex-flow: column;
        padding-right: 24px;

        & > label:first-child {
          font-size: 14px;
          font-weight: 700;
          line-height: 16px;
        }
        & > label:nth-child(2) {
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
          color: #006aff;
        }

        .document-main-info-tags {
          padding-top: 16px;
          display: flex;

          .document-tag {
            display: flex;
            align-items: center;
            font-weight: bold;
            font-size: 12px;
            height: 20px;
            background-color: #f2f8ff;
            margin: 0 4px;
            padding: 0 16px;
            border-radius: 60px;
          }
        }
      }

      button {
        margin: 0 24px 0 auto;
      }
    }
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.missing-fields-card {
  background-color: #fff6e5;
  border: 1px solid #ff9900;
  padding: 20px;
  margin-bottom: 0px;
  border-radius: 5px;
  color: #cc6600;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  h3 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #cc6600;
  }

  img {
    margin-right: 10px;
  }

  ul {
    margin: 0;
    padding-left: 20px;
    list-style-type: disc;

    li {
      margin-bottom: 5px;
    }
  }
}
