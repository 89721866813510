.drag-and-drop_modal_btns{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    width: 100%;
    .button{
        width: 48%;
        &:only-child {
            width: 100%;
          }
    }
}
.drag-and-drop__uploadedList {
    display: grid;
    grid-template-columns: repeat(2, 48%);
    gap: 24px;
    list-style: none;
    padding: 0;
    margin: 0;
}  
.csv-excel-importer-button{
    display: flex;
    padding: 8px 32px;
    justify-content: center;
    align-items: center;
}
.csv-excel-importer-button-left{
    display: flex;
    padding: 8px 32px;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
    margin-bottom: 18px;
}
.drag-and-drop_file_card{
    margin-top: 24px;
    padding: 12px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 24px;
    border-radius: 8px;
    border: 1px solid var(--grey-350, #D5D5D5);
    background: var(--White, #FFF);
    &_content{
        display: flex;
        align-items: center;
        gap: 24px;
        align-self: stretch;
        &_title{
            display: flex;
            align-items: center;
            gap: var(--space-XS, 4px);
            flex: 1 0 0;
            min-width: 0;
            &_icon{
                display: flex;
                padding: 6px;
                justify-content: center;
                align-items: center;
                align-self: stretch;
            }
            &_text{
                display: flex;
                flex-direction: column;
                margin-right: auto;
                white-space: nowrap;
                overflow: auto;
                text-overflow: ellipsis;
                &__title{
                    color: var(--grey-800, #242424);
                    font-feature-settings: 'clig' off, 'liga' off;

                    /* S (800) */
                    font-family: Inter;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 16px; /* 114.286% */
                }
                &__subtitle{
                    color: var(--nmap-500-primary, var(--highlight-primary, #006AFF));
                    font-feature-settings: 'clig' off, 'liga' off;

                    /* XS (500) */
                    font-family: Inter;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 16px; /* 133.333% */
                }
            }
        }
        &_btns{
            display: flex;
            &__open{
                display: flex;
                padding: 6px 12px;
                justify-content: center;
                align-items: center;
                margin-right: 7px;
            }
            &__cross{
                display: flex;
                padding: 6px;
                justify-content: center;
                align-items: center;
                border-radius: 8px;
                background: var(--grey-200, #F3F3F3);
            }
        }
    }
}
.subtitle{
    color: var(--grey-800, #242424);

    /* M (500) */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
}
.drag-and-drop_modal{
    &_filelist{
        padding: 60px 16px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap: 16px;
        align-self: stretch;
        &__label{
            display: flex;
            width: 143px;
            padding-right: 0px;
            align-items: center;
            gap: 6px;
            color:#242424;
        }
        &__list{
            display: flex;
            align-items: flex-start;
            gap: 16px;
            align-self: stretch;
            list-style: none;
            padding: 0px;
            & li{
                flex: 1 0 0;
                color: #000;
                font-feature-settings: 'clig' off, 'liga' off;

                /* S (800) */
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 16px; /* 114.286% */
                display: flex;
                align-items: center;
                justify-content: space-between;
                & button{
                    display: flex;
                    padding: 6px;
                    justify-content: center;
                    align-items: center;
                    border-radius: 8px;
                    background: var(--grey-200, #F3F3F3);
                }
            }
        }
    }
    &_dropzone{
        display: flex;
        padding: 70px 16px;
        flex-direction: column;
        align-items: center;
        gap: 4px;
        align-self: stretch;
        border-radius: 8px;
        border: 1px dashed var(--grey-400, #B3B3B3);
        & span{
            color: #000;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; /* 150% */
        }
    }
}
.drag-and-drop_modal_loading{
    display: flex;
    padding: 85px 16px;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    align-self: stretch;
}