.card {
  background: #ffffff;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  &--default {
    box-shadow: 0px 0px 9px #d1d1d1;
    border-radius: 0.5rem;
    padding: 1rem;
  }
  &--blue {
    background: #f4f8ff;
    border-radius: 0.5rem;
    padding: 1rem;
  }
  &--blue-with-hover {
    background: #f4f8ff;
    border-radius: 0.5rem;
    padding: 1rem;
    &:hover {
      background: darken(#f4f8ff, 2);
    }
  }
}
