.solas-security-measures {
  .additional-security-measures-form {
    padding: 0 0 1rem 0;
  }
  .solas-security-measures-table {
    padding: 1rem 0;
  }
  .procedures-followed-form {
    padding: 1rem 0;
  }
  .solas-port-calls-table {
    padding: 1rem 0;
    h4 {
      margin: 0;
    }
  }
}



.solas-security-measures-table {
  &__added-header {
    background: #f2f8ff;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
  }
  &__title {
    font-weight: bold;
    font-size: 1.5rem;
  }
}

.solas-security-measures-modal {
  width: 40%;

  .modal__header-container {
    justify-content: flex-start;
  }
  .solas-security-measures-form {
    .solas-security-measures-form__row1 {
      display: flex;
      justify-content: space-between;
      .form-control {
        flex: 1;
      }
      .field_date_picker{
         margin: 0;
         flex:1;
         display: flex;
         flex-direction: column;
         z-index: 0;
         &:has(.react-datepicker-popper){
           z-index: 99999 !important;
         }
        .react-datepicker__input-container{
          width: 100% !important;
        }
       }
      &:nth-child(1) {
        .form-control {
          &:nth-child(1){
            max-width: 80px;
          }
          &:nth-child(1), &:nth-child(2){
            margin-right: 1rem;
          }
        }
      }
    }
    .add-member__form-submission-btns {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin: 1rem 0 0 auto;
      .discard-btn {
        text-align: center;
        cursor: pointer;
      }
      .discard-btn,
      button {
        flex: 0 0 50%;
      }
    }
  }
}

.solas-ship-to-ship-table {
  &__added-header {
    background: #f2f8ff;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
  }
  &__title {
    font-weight: bold;
    font-size: 1.5rem;
  }
}

.add-ship-to-ship-modal {
  width: 60%;
  .modal__header-container {
    justify-content: flex-start;
  }
  .add-ship-to-ship-form {
    .add-ship-to-ship-form__row {
      display: flex;
      justify-content: space-between;
      .form-control {
        flex: 1;
      }
      &:nth-child(1), &:nth-child(2) {
        .form-control {
          &:not(:last-child) {
            margin-right: 1rem;
          }

        }
      }
      .field_date_picker{
        margin: 0;
        flex:1;
        display: flex;
        flex-direction: column;
        z-index: 0;
        &:has(.react-datepicker-popper){
          z-index: 99999 !important;
        }
        &:last-child .react-datepicker__input-container{
          width: 100% !important;
        }
        .react-datepicker__input-container{
          width: 98% !important;
        }
      }
    }
    .add-ship-to-ship__form-submission-btns {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin: 1rem 0 0 auto;
      .discard-btn {
        text-align: center;
        cursor: pointer;
      }
      .discard-btn,
      button {
        flex: 0 0 50%;
      }
    }
  }
}
