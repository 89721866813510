.page-header {
  margin-bottom: 32px;

  .page-title {
    color: #212121;
    font-size: 20px;
    margin: 0;
    margin-bottom: 24px;
    padding: 1rem 0;
    padding-bottom: 0.5rem;
  }
  
  .page-subtitle {
    margin: 0;
    padding: 0;
  }
}
