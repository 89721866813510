.radio-buttons-custom {
  margin-bottom: 0.5rem;
  &__label {
    font-weight: bold;
    display: flex;
    margin-bottom: 0.5rem;
    color: #000a19;
  }
  &__description {
    display: flex;
    margin-bottom: 0.5rem;
    color: #242424;
  }
  &__container {
    display: flex;
  }
  .radio-button-custom {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
    padding: 0.5rem 1rem;
    border-radius: 0.5em;
    border: 1px solid #006AFF;
    margin-right: 0.5rem;
    cursor: pointer;
    input {
      appearance: none;
    }
    label {      
      color: #006AFF;
      cursor: pointer;
      font-weight: 700;
    }
    img {
      width: 1.1rem;
      height: 1.1rem;
    }
    &.checked {
      background: #E0EDFF;
    }
  }
}
