.checkbox-group {
  margin-bottom: 0.5rem;
  &__label {
    font-weight: bold;
    display: flex;
    margin-bottom: 0.5rem;
    color: #000A19;
  }
  .checkbox {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
  }
}
