.marpol {
  width: 100%;

  .select-annexes {
    .select-annexes-form {
      .checkbox-group-custom__checkboxes {
        display: flex;

        .checkbox {
          margin-right: 1rem;
        }
      }
    }
  }

  .annex-tabs {
    display: flex;
    padding: 1rem 0 1rem 0;

    .annex-tab-item {
      cursor: pointer;
      padding-right: 1rem;
      display: flex;
      align-items: center;

      svg {
        margin-left: 3px;
      }
      &.active {
        color: #006aff;
        font-weight: bold;
      }

      &.not-selectable {
        opacity: 0.2;
        cursor: not-allowed;
      }
    }
  }

  .marpol-table {
    overflow: scroll;
    flex-direction: column;

    &__header {
      display: flex;

      .cell {
        background-color: #f2f8ff;
        font-weight: bold;
        // padding-top: 1rem;
        // padding-bottom: 1rem;
        height: 6rem;
      }
    }

    &__body {
    }

    &__row {
      display: flex;

      input[type='number'] {
        padding: 0.5rem;
        height: 2rem;
        width: 100%;
        box-sizing: border-box;
        border: none;
        outline: none;
      }

      .form-control {
        width: 100%;
        height: 100%;

        input {
          height: 100%;
        }
      }
    }

    &__row,
    &__header {
      .cell {
        &:nth-child(1) {
          padding-left: 1rem;
        }
      }
    }

    .cell {
      border-bottom: 1px solid #e5e5e5;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      color: #242424;
      padding: 0.5rem 0;
      font-size: 0.8rem;

      &:nth-child(1) {
        flex: 0 0 300px;
      }

      &:nth-child(2) {
        flex: 0 0 200px;
        width: 200px;
      }

      &:nth-child(3) {
        flex: 0 0 200px;
        width: 200px;
      }

      &:nth-child(4) {
        flex: 0 0 200px;
        width: 200px;
      }

      &:nth-child(5) {
        flex: 0 0 200px;
        width: 200px;
      }

      &:nth-child(6) {
        flex: 0 0 200px;
        width: 200px;
      }

      &:nth-child(7) {
        flex: 0 0 200px;
        width: 200px;
      }

      &.mixed-type {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        input {
          margin: 0.5rem 0.5rem 0.5rem 0;
        }
      }
    }
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  opacity: 0.8;
}

////////////////////////////////////////////////////////////////////////////////

.marpol {
  .form-control {
    width: 100%;
    margin-bottom: initial;
  }

  tr {
    td {
      padding: 0;

      &:nth-child(1) {
        padding-left: 1rem;
      }
    }
  }
}

.marpol {
  .cell {
    .form-control.multi-select {
      display: flex;
      align-items: center;

      .react-select-container {
        width: 100%;

        .react-select__control {
          border: none;
          outline: none;
          box-shadow: none;
        }
      }
    }
  }
}

.upload-certificate-section {
  margin-bottom: 1rem;
  .field_date_picker {
    margin: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    z-index: 0;
    &:has(.react-datepicker-popper) {
      z-index: 99999 !important;
    }
    .react-datepicker__input-container {
      width: 100% !important;
    }
  }

  .form-control {
    width: 40%;
    margin-top: 1rem;
  }

  p {
    margin: 0;
    margin-bottom: 1rem;

    span {
      font-weight: bold;
    }
  }
}

.marpol-tables {
  h4 {
    margin: 0;
    margin-bottom: 1rem;
  }
}

.last-waste {
  margin-top: 1rem;

  .radio-buttons-custom {
    margin-bottom: 1rem;
  }
}

.last-waste-delivery-section {
  h3 {
  }

  .multi-select {
    margin-bottom: 1rem;
    width: 40%;
  }

  &__fields {
    display: flex;
    flex-wrap: wrap;
    .field_date_picker {
      font-size: 16px;
      margin: 0;
      flex: 0 0 52%;
      display: flex;
      flex-direction: column;
      z-index: 0;
      &:has(.react-datepicker-popper) {
        z-index: 99999 !important;
      }
      .react-datepicker__input-container {
        width: 88% !important;
      }
    }

    .form-control {
      flex: 0 0 48%;
      padding-right: 2%;
    }
  }
}

.un-container {
  position: relative;
  width: 100%;

  .list {
    position: absolute;
    left: 0;
    background: white;
    max-height: 10rem;
    width: 20rem;
    border-radius: 0.25rem;
    border: 1px solid black;
    overflow: scroll;
    font-size: 0.8rem;
    z-index: 9999;

    &__item {
      cursor: pointer;
      // background: #a6c3ff5e;
      margin: 0.25rem 0;
      padding: 0.5rem 0.5rem;

      &:hover {
        background: #a6c3ff5e;
      }
    }
  }

  .un__empty-case {
    display: block;
    padding: 0.5rem 1rem;
  }
}

.marpol-error {
  display: flex;
  flex-direction: column;
  align-items: end;
  p {
    margin: 2px 0;
  }
}

.waste-declaration-table-port-dropdown {
  width: 150px;
}
