.last-and-next-port {
  .last-and-next-port-form {
    &__controls {
      &__row {
        display: flex;
        align-items: flex-start;
        min-height: 80px;
        flex-wrap: wrap;

        .form-control {
          display: flex;
          flex-direction: column;
          flex-basis: 50%;
          max-width: 300px;
          position: relative;
          min-height: 100px;
        }

        .field_date_picker_lastPort,
        .field_date_picker_nextPort {
          margin: 0;
          flex: 1;
          z-index: 0;

          .react-datepicker-wrapper {
            margin-left: 20px;
            width: 100%;
          }

          &:has(.react-datepicker-popper) {
            z-index: 99999 !important;
          }
        }

        & > *:first-child {
          margin-right: 16px;
        }

        & > *:last-child {
          margin-left: 16px;
        }

        .form-control.multi-select {
          & > .react-select-container {
            min-width: 200px;
            width: 100%;
          }
        }

        .form-control.date-picker {
          display: flex;
          align-items: center;

          .date-picker__label {
            margin-right: 16px;
          }

          .date-picker__inner {
            min-width: 190px;
          }
        }
      }
    }
  }
}
