.cargo-table {
  &__added-header {
    margin-top: 1rem;
    background: #f2f8ff;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
  }
  &__title {
    font-weight: bold;
    font-size: 1.5rem;
  }
  &__control-btns {
    display: flex;
    button {
      margin-left: 1rem;
    }
  }
  &__scrollable-area {
    overflow: scroll;
  }
}

.crew-changes-form {
  display: flex;
  padding-top: 15px;
}

.cargo-documents {
  h4 {
    margin-bottom: 12px;
  }

  .documents-list {
    display: flex;
    flex-wrap: wrap;

    .upload-download-box {
      flex: 0 0 48%;
      margin-bottom: 2rem;

      &:nth-child(odd) {
        margin-right: 2%;
      }

      &:nth-child(even) {
        margin-right: 0;
      }
    }
  }
}

.add-cargo-modal {
  min-width: 40%;
  label {
    font-size: 0.8rem;
  }
  .add-cargo-modal__content {
    .add-cargo-form {
      &__row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .form-control {
          flex: 1;
          padding-right: 1rem;
          &:last-child {
            padding-right: initial;
          }
        }
      }
    }
  }
  .add-cargo__form-submission-btns {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 1rem 0 0 auto;
    .discard-btn {
      text-align: center;
      cursor: pointer;
    }
    .discard-btn,
    button {
      flex: 0 0 50%;
    }
  }
}
