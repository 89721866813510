// https://moderncss.dev/pure-css-custom-checkbox-style/
.checkbox-group-bunkering {
  margin-bottom: 1rem;
  &__label {
    font-weight: bold;
    display: flex;
    margin-bottom: 0.5rem;
    color: #000a19;
  }
  &__checkboxes {
    display: flex;
  }
  .checkbox {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
    padding: 0.5rem 1rem;
    border-radius: 1em;
    border: 1px solid #006aff;
    margin-right: 0.5rem;
    width: 7rem;
    cursor: pointer;
    display: grid;
    grid-template-columns: 1em auto;
    gap: 0.5em;
    label {
      cursor: pointer;
    }
    padding: 0.5rem;
    border-radius: 0.25rem;
    transition: 100ms;
    &.checked {
      background: #e6f0ff;
    }
    &:hover {
      background: darken(#f4f8ff, 0.3);
    }
  }

  input[type='checkbox'] {
    cursor: pointer;
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    appearance: none;
    /* For iOS < 15 to remove gradient background */
    background-color: #fff;
    /* Not removed via appearance */
    margin: 0;

    font: inherit;
    color: currentColor;
    width: 1em;
    height: 1em;
    border: 0.1rem solid #0041cb;
    border-radius: 50%;
    transform: translateY(-0.075em);

    display: grid;
    place-content: center;
  }

  input[type='checkbox']::before {
    content: '';
    width: 0.65em;
    height: 0.65em;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    border-radius: 50%;
    box-shadow: inset 1em 1em #006aff;
    /* Windows High Contrast Mode */
    background-color: CanvasText;
  }

  input[type='checkbox']:checked::before {
    transform: scale(1);
  }
  input[type='checkbox']:focus {
    // outline: max(2px, 0.15em) solid currentColor;
    // outline-offset: max(2px, 0.15em);
  }
}
