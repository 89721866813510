.effects-list-table {
  padding: 2rem 0;

  &__added-header {
    background: #f2f8ff;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
  }

  &__title {
    font-weight: bold;
    font-size: 1.5rem;
  }

  &__scrollable-area {
    overflow: scroll;
  }
}

.add-effect-modal {
  min-width: 40%;

  label {
    font-size: 0.8rem;
  }

  .add-effect-modal__content {
    .add-effect-form {
      &__row {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .form-control {
          flex: 1;
          padding-right: 1rem;
          &:last-child {
            padding-right: initial;
          }
        }
      }

      .button {
        margin: 1rem 0 0 auto;
      }
    }
  }

  .add-member__form-submission-btns {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 1rem 0 0 auto;

    .discard-btn {
      text-align: center;
      cursor: pointer;
    }

    .discard-btn,
    button {
      flex: 0 0 50%;
    }
  }
}